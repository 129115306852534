.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1500px;
  overflow: hidden;
  padding: 0 20px 0px 20px;
  text-align: center;
}

.DocumentsUL {
  text-align: left;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

.DocumentsUL > li {
  margin-right: 5px;
  margin-left: 5px;
}

.DocumentsP {
  font-size: 16px;
  font-weight: bold;
  color: #00CC99;
}

.Button {
  font-size: 14px;
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.ButtonProgram {
  font-size: 20px;
  background: none!important;
  border: none;
  padding: 0!important;
  font-weight: bold;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #00CC99;
  text-decoration: underline;
  cursor: pointer;
}

.ProgramHeaderUL {
  text-align: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: row wrap;
}

.ProgramHeaderUL > li {
  margin-right: 15px;
  margin-left: 15px;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

h1, h2, h3 {
  color: #2A3B94;
  font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
}

h4, h5, h6 {
  color: #00CC99;
  font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
}

.nav-link {
  font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
  display: inline-block;
  color: #2A3B94;
  text-decoration: none;
  padding: 8px 8px;
  font-weight: bold;
  align-self: center;
}

.nav-link:hover {
  color: #00CC99;
  transition: color 0.3s ease;
}

.active {
  font-weight: bold;
}

.table-open-hours {
  align-self: center;
}

.table-open-hours td {
  font-weight: normal;
  font-size: 13px;
  border-bottom: #00CC66 1px solid;
  background-color: #E7EFFF;
  vertical-align: top;
  width: 20%;
  padding: 5px;
}

.School {
  margin-left: 8px;
  margin-right: 8px;
}
.Admissions {
  margin-left: 8px;
  margin-right: 8px;
}
.AfterCM2 {
  margin-left: 8px;
  margin-right: 8px;
}
.Program {
  margin-left: 8px;
  margin-right: 8px;
}
.ExtraAct {
  margin-left: 8px;
  margin-right: 8px;
}
.Legal {
  margin-left: 8px;
  margin-right: 8px;
}
